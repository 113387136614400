jQuery(document).ready(function($) {

	// CLICK FUNCTIES +++++++++++++++++++++++++++ //

	// Hamburger toggle -----------
	$('.hamburger-mob').on("click", function(){
		$(this).toggleClass("actief");
		$('.nav-mobile-menu').toggleClass("actief");
	});

	$('.hamburger-desk').on("click", function(){
		$(this).toggleClass("actief");
		$('.nav-hamurger-menu').toggleClass("actief");
	});

	// Algemene toggle -----------
	$('.toggle').on("click", function(){
		$(this).toggleClass("actief");
	});

	// Actieveren popup -----------
	$('.poptrig').on("click", function(){$('.popup').addClass("toon");$('body').addClass("noscroll");});
	$('.inner .sluiten ,body').on("click", function(){$('.popup').removeClass("toon");$('body').removeClass("noscroll");});
	$(".poptrig,.popup .inner").click(function(e){e.stopPropagation();});

	
	// COOKIES ++++++++++++++++++++++++++ //

	// melding-popup
	$('.melding-popup .sluiten').click(function() { $('.melding-popup').addClass('hidden');
	Cookies.set('melding-popup.hide','true', { expires: 7 });});
	if (Cookies.get('melding-popup.hide')) {$('.melding-popup').addClass('keep-hidden');}

	// melding-boven
	$('.melding-boven .sluiten').click(function() { $('.melding-boven').addClass('hidden');
	Cookies.set('melding-boven.hide','true', {element: '.melding-boven'}, { expires: 7 });});
	if (Cookies.get('melding-boven.hide')) {$('.melding-boven').addClass('keep-hidden');}

	// SCROLL FUNCTIES ++++++++++++++++++++++++++ //

	// Back to top -----------
	$('.backtotop').click(function(){
    	$("html, body").animate({ scrollTop: 0 }, 600);
    	return false;
	});

	$(window).scroll(function() {var scroll = $(window).scrollTop();
		if ($(window).scrollTop() > 400) {$(".backtotop").addClass("toon");}
	   else if ($(window).scrollTop() <= 400) {$(".backtotop").removeClass("toon"); }
	});

	$(window).scroll(function() {
	   if($(window).scrollTop() + $(window).height() == $(document).height()) { $('.backtotop').addClass("fix");}
	   else { $('.backtotop').removeClass("fix"); }
	});

	// VASTE VENDORS +++++++++++++++++++++++++++ //

	// Toewijzen justified gallery -----------
	if ($(".acf-justigal")[0]){ $(".acf-justigal").justifiedGallery(); }

	// CUSTOM SCRIPTS KOMEN HIER ONDER --------------------------------------------------------------------------//
	//-----------------------------------------------------------------------------------------------------------//


	// ellipsis

	$('.toggle-button').click(function() {
		// Find the closest text-container element to this toggle button
		var container = $(this).prev('.text-container');
		if (container.css('max-height') === 'none') {
		  container.css('max-height', '6em');
		  $(this).text('Bekijk meer');
		} else {
		  container.css('max-height', 'none');
		  $(this).text('Bekijk minder');
		}
	 });

	// custom inview trigger

	// Function to check if an element is in view
	function isInView($elem, offset) {
		var docViewTop = $(window).scrollTop();
		var docViewBottom = docViewTop + $(window).height();
	
		var elemTop = $elem.offset().top;
		var elemBottom = elemTop + $elem.height();
	
		// Use the offset to adjust when the class is added
		return ((elemBottom >= docViewTop + offset) && (elemTop <= docViewBottom - offset));
	}
	
	// Function to toggle the inview class
	function toggleInViewClass() {
		$('.anim').each(function() {
			var $this = $(this);
	
			// You can adjust the offset here (positive or negative)
			var offset = 200; // for example, 100 pixels offset
	
			// Add 'inview' class if in view, and optionally remove it if not in view
			if (isInView($this, offset)) {
			$this.addClass('anim-start');
			} else if ($this.data('reset-inview')) { // Check if reset option is enabled
			$this.removeClass('anim-start');
			}
		});
	}
	
	// Document ready
	$(function() {
		// Initial check if elements are in view
		toggleInViewClass();
	
		// Check on scroll and resize
		$(window).on('scroll resize', toggleInViewClass);
	});
	
	// Option to enable/disable class reset on scroll out of view
	$.fn.enableResetInView = function() {
		return this.data('reset-inview', true);
	};
	$.fn.disableResetInView = function() {
		return this.data('reset-inview', false);
	};
	 

	 
	 // Usage
	 $('.anim').enableResetInView(); // To enable the reset of the inview class
	 // $('.anim').disableResetInView(); // To disable the reset of the inview class


	// Download Manager - Opslaan van Email in Cookie
	$('#input_6_3').change(function(){
		Cookies.remove('download-email');
		Cookies.set('download-email',this.value, {expires: 30, domain: 'www.jabba.energy'});
	});

	// Download Manager - Archive Downloads Dynamic fill in form hidden fields
	$('.section-documenten-media').on("click", ".btn-modal-download", function(){
		var DownloadTitle = $(this).attr('data-download-title');
		var DownloadFile = $(this).attr('data-download-url');
		$("#DownloadTitle").html(DownloadTitle);
		$('#input_6_28').val(DownloadTitle);
		$('#input_6_27').val(DownloadFile);
		if (Cookies.get('download-email')){
			var DownloadEmail = Cookies.get('download-email');
			$('#input_6_3').val(DownloadEmail);
		}
	});

	// Download Manager - Reloaden van pagina om nieuwe download mogelijk te maken. (Ivm form in Modal)
	$('#gform_submit_button_6').on("click", function(){
		setTimeout(location.reload.bind(location), 5000);
	});

	//swiper-videos --------------------------------------
	var swiper = new Swiper('.swiper-videos', {
		//autoplay: {delay: 5000,},
		speed: 300,
		autoHeight: false,
		grabCursor: true,
		loop: false,
		watchOverflow: true,
		centeredSlides: false,
		slidesOffsetBefore: 0,
		slidesOffsetAfter: 0,
		direction: 'horizontal',
		effect: 'slide', // 'slide', 'fade', 'cube', 'coverflow' or 'flip'
		breakpoints: { // breakpoints gelden voor alles erboven
			0: { 
				slidesPerView: 1,
				spaceBetween: 20
			},
			767: {
				slidesPerView: 2,
				spaceBetween: 20
			},
			992: {
				slidesPerView: 2,
				spaceBetween: 20
			}
		},	
		navigation: {
			nextEl: '.swiper-videos-next',
			prevEl: '.swiper-videos-prev',
		},
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',// "bullets", "fraction", "progressbar"
			clickable: true,
		},
	});

	//swiper-rel-blog --------------------------------------
	var swiper = new Swiper('.swiper-rel-blog', {
		//autoplay: {delay: 5000,},
		speed: 300,
		autoHeight: false,
		grabCursor: true,
		loop: false,
		watchOverflow: true,
		centeredSlides: false,
		slidesOffsetBefore: 0,
		slidesOffsetAfter: 0,
		direction: 'horizontal',
		effect: 'slide', // 'slide', 'fade', 'cube', 'coverflow' or 'flip'
		breakpoints: { // breakpoints gelden voor alles erboven
			0: { 
				slidesPerView: 1,
				spaceBetween: 20
			},
			767: {
				slidesPerView: 1,
				spaceBetween: 20
			},
			992: {
				slidesPerView: 1,
				spaceBetween: 20
			}
		},	
		navigation: {
			nextEl: '.swiper-rel-blog-next',
			prevEl: '.swiper-rel-blog-prev',
		},
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',// "bullets", "fraction", "progressbar"
			clickable: true,
		},
	});

	//swiper-reviews --------------------------------------
	var swiper = new Swiper('.swiper-reviews', {
		//autoplay: {delay: 5000,},
		speed: 300,
		autoHeight: false,
		grabCursor: true,
		loop: false,
		watchOverflow: true,
		centeredSlides: false,
		slidesOffsetBefore: 0,
		slidesOffsetAfter: 0,
		direction: 'horizontal',
		effect: 'slide', // 'slide', 'fade', 'cube', 'coverflow' or 'flip'
		breakpoints: { // breakpoints gelden voor alles erboven
			0: { 
				slidesPerView: 1,
				spaceBetween: 20
			},
			767: {
				slidesPerView: 1,
				spaceBetween: 20
			},
			992: {
				slidesPerView: 1,
				spaceBetween: 20
			}
		},	
		navigation: {
			nextEl: '.swiper-reviews-next',
			prevEl: '.swiper-reviews-prev',
		},
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',// "bullets", "fraction", "progressbar"
			clickable: true,
		},
	});

	Fancybox.bind("[data-fancybox]", {
		// Custom options for all galleries
	});



});